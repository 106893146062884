import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getSpeakersRequest } from './api/getOurFocusRequest';

import {
    getSpeakers,
    getSpeakersSuccess,
    getSpeakersFailure,
    getSpeakersIdle,
} from './actions';

import { SpeakersResponse } from './types';

function* Saga() {
    try {
        const Speakers: SpeakersResponse = yield call(getSpeakersRequest);
        yield put(getSpeakersSuccess(Speakers));
    } catch (error) {
        console.log(error);
        yield put(getSpeakersFailure(error));
    }
}

export function* SpeakersWatcher() {
    yield takeEvery(getSpeakers.toString(), Saga);
}
