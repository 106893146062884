import { createActions } from 'redux-actions';
import { PodcastsResponse, PodcastsPayload } from './types';

const { getPodcastsFailure, getPodcastsIdle, getPodcastsClear } = createActions(
    'GET_PODCASTS_FAILURE',
    'GET_PODCASTS_IDLE',
    'GET_PODCASTS_CLEAR'
);
const { getPodcasts } = createActions<PodcastsPayload>('GET_PODCASTS');

const { getPodcastsSuccess } = createActions<{
    podcasts: PodcastsResponse;
    topPodcasts: PodcastsResponse;
}>('GET_PODCASTS_SUCCESS');

export {
    getPodcastsFailure,
    getPodcastsIdle,
    getPodcasts,
    getPodcastsSuccess,
    getPodcastsClear,
};
