export const apiUrl = 'https://api.smeni-parol.ru';
export const domain = 'https://api.smeni-parol.ru';
export const shareLink = 'https://smeni-parol.ru';
export const trackBaseUrl = 'https://api.smeni-parol.ru';
export const token =
    'c242c4378c513b3fccac881cc29bf9b6b24f87eeee4404f432f0c1ca5648056e65871a7775b37cebfcc7bc273a0410cf965f33f83aa7b23d516332a6e71942e58eb2019bc12a3b36c848c4ada7fc431a724024ad223736bc37039363b4ef1eba366ffcd6d0c8c2720e462621596d48c8bac7c1b58813fc053884637efcc6062c';

let local = 'en';

export const locale = local;

export const SUBSCRIBE_LINKS = {
    APPLE:
        'https://podcasts.apple.com/us/podcast/%D1%81%D0%BC%D0%B5%D0%BD%D0%B8-%D0%BF%D0%B0%D1%80%D0%BE%D0%BB%D1%8C/id1570896327',
    YANDEX: 'https://music.yandex.ru/album/16065230',
    VK: 'https://vk.com/podcasts-21022222',
    GOOGLE:
        'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5saWJzeW4uY29tLzQ2NjcyMi9yc3M',
    SPOTIFY: 'https://open.spotify.com/show/7E6ntH4kjs1C2fPPRgqV1N',
    CASTRO: 'https://castro.fm/podcast/b8293c98-44ec-4a6a-946a-ab53a07820db',
    CASTBOX: 'https://castbox.fm/channel/5369981',
    POCKETCAST: 'https://pca.st/5cnwolnt',
    YOUTUBE:
        'https://youtube.com/playlist?list=PLQQwOgZzcgt3r_w0B4dnZwuTbVntP2z6e&si=9MXwVgAG13FFfdNz',
    ZVUK: 'https://zvuk.com/podcast/16204108',
};

export const GTM = {
    HEAD: `<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WZ7LJ3');</script>
<!-- End Google Tag Manager -->`,
    BODY: `<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WZ7LJ3"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
`,
};
