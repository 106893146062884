import { call, takeEvery, put, all } from 'redux-saga/effects';

import {
    getPodcastsRequest,
    getTopPodcastsRequest,
} from './api/getPodcastsRequest';

import {
    getPodcasts,
    getPodcastsSuccess,
    getPodcastsFailure,
    getPodcastsIdle,
} from './actions';

import { PodcastsResponse } from './types';

function* Saga(action: any) {
    const { payload } = action;
    
    try {
        const TopPoscasts: PodcastsResponse = yield call(getTopPodcastsRequest);
        const Podcasts: PodcastsResponse = yield call(getPodcastsRequest, payload);
        yield put(
            getPodcastsSuccess({ podcasts: Podcasts, topPodcasts: TopPoscasts })
        );
    } catch (error) {
        console.log(error);
        yield put(getPodcastsFailure(error));
    }
}

export function* PodcastsWatcher() {
    yield takeEvery(getPodcasts.toString(), Saga);
}
