import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Header, Footer } from 'components';
import routes from 'routes';
// import "../../../static/styles/main.scss";
import "../../../static/styles/App.scss";
import "../../../static/styles/normalize.scss";
import "../../../static/styles/_custom.scss";
import "../../../static/styles/_mixins.scss";
import "../../../static/styles/_variables.scss";
// import "../../../static/styles/style.css";
// import "../../../static/styles/custom.css";
// import "../../../static/styles/animations.css";
import { initData } from 'store/ducks/app/actions';
import { State } from '../../types';

function App() {
    const location = useLocation();
    const path = location.pathname;

    return (
        <div
            className={cn(
                'root'
            )}
        >
            {/* <Header /> */}
            <Switch>
                {routes.map(({ fetchData, ...routeProps }) => {
                    return <Route key={routeProps.path} {...routeProps} />;
                })}
            </Switch>
            {/* <Footer /> */}
        </div>
    );
}

const Component = hot(App);

export { Component as App };
