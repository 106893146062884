import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getPodcast,
    getPodcastSuccess,
    getPodcastFailure,
    getPodcastIdle,
} from './actions';
import { PodcastState, PodcastResponse } from './types';
import { Status } from 'types';

export const initialState: PodcastState = {
    status: 'IDLE',
    data: [],
};
const status = handleActions<Status>(
    {
        [getPodcastSuccess.toString()]: () => 'SUCCESS',
        [getPodcast.toString()]: () => 'LOADING',
        [getPodcastFailure.toString()]: () => 'FAILURE',
        [getPodcastIdle.toString()]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getPodcastSuccess.toString()]: (
            state,
            action: { payload: PodcastResponse }
        ) => action.payload.data,
    },
    initialState.data
);

const Podcast = combineReducers({
    status,
    data,
});

export { Podcast };
