import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getPodcastRequest } from './api/getPodcastRequest';

import {
    getPodcast,
    getPodcastSuccess,
    getPodcastFailure,
    getPodcastIdle,
} from './actions';

import { PodcastResponse, PodcastPayload } from './types';

function* Saga(action: { payload?: PodcastPayload; type: string }) {
    try {
        const { payload } = action;
        const Podcast: PodcastResponse = yield call(getPodcastRequest, payload);
        yield put(getPodcastSuccess(Podcast));
    } catch (error) {
        console.log(error);
        yield put(getPodcastFailure(error));
    }
}

export function* PodcastWatcher() {
    yield takeEvery(getPodcast.toString(), Saga);
}
