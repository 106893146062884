import { createActions } from 'redux-actions';
import { PodcastResponse, PodcastPayload } from './types';

const { getPodcastFailure, getPodcastIdle } = createActions(
    'GET_PODCAST_FAILURE',
    'GET_PODCAST_IDLE'
);

const { getPodcast } = createActions<PodcastPayload>('GET_PODCAST');
const { getPodcastSuccess } = createActions<PodcastResponse>(
    'GET_PODCAST_SUCCESS'
);

export { getPodcastFailure, getPodcastIdle, getPodcast, getPodcastSuccess };
