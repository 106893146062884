import fetch from 'isomorphic-fetch';
import { apiUrl, token, locale } from '../../../../config';
import { PodcastPayload } from '../types';
export const getPodcastRequest = (payload: PodcastPayload) => {
    const url =
        apiUrl +
        '/api/podcast-plural/?filters[Slug][$eq]=' +
        payload.slug +
        '&populate=Platform,File,Thumbnail';
        
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
            console.log('error', error);
        });
};
