import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getPodcasts,
    getPodcastsSuccess,
    getPodcastsFailure,
    getPodcastsIdle,
    getPodcastsClear,
} from './actions';
import {
    PodcastsState,
    PodcastsResponse,
    PodcastsPayload,
    PodcastsData,
} from './types';
import { Status } from 'types';

export const initialState: PodcastsState = {
    status: 'IDLE',
    podcasts: [
        // {
        //     id: 0,
        //     attributes: {
        //         Title: '',
        //         Content: '',
        //         Date: '',
        //         Description: '',
        //     },
        // },
    ],
    topPodcasts: [],
    meta: {},
};
const status = handleActions<Status>(
    {
        [getPodcastsSuccess.toString()]: () => 'SUCCESS',
        [getPodcasts.toString()]: () => 'LOADING',
        [getPodcastsFailure.toString()]: () => 'FAILURE',
        [getPodcastsIdle.toString()]: () => 'IDLE',
    },
    initialState.status
);

const podcasts = handleActions<PodcastsData[], PodcastsPayload>(
    {
        [getPodcasts.toString()]: (state, action) => {
            if (
                (action.payload &&
                    action.payload.start &&
                    action.payload.start === 1) ||
                (action.payload && !action.payload.start)
            ) {
                return [];
            } else {
                return state;
            }
        },
        [getPodcastsSuccess.toString()]: (state, action) => [
            ...state,
            ...action.payload.podcasts.data,
        ],
        [getPodcastsIdle.toString()]: () => [],
    },
    initialState.podcasts
);
const topPodcasts = handleActions(
    {
        [getPodcastsSuccess.toString()]: (
            state,
            action: {
                payload: {
                    podcasts: PodcastsResponse;
                    topPodcasts: PodcastsResponse;
                };
            }
        ) => action.payload.topPodcasts.data,
    },
    initialState.topPodcasts
);
const meta = handleActions(
    {
        [getPodcastsSuccess.toString()]: (
            state,
            action: {
                payload: {
                    podcasts: PodcastsResponse;
                    topPodcasts: PodcastsResponse;
                };
            }
        ) => action.payload.podcasts.meta,
    },

    initialState.meta
);

const Podcasts = combineReducers({
    status,
    podcasts,
    topPodcasts,
    meta,
});

export { Podcasts };
