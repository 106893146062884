import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { State } from 'types';
// import { layouts } from './ducks/app/reducer';
// import { careersReducer } from './ducks/careers/reducer';
// import { our_focus } from './ducks/our_focus/reducer';
// import { home } from "./ducks/home/reducer";
import { Speakers } from './ducks/speakers/reducer';
import { Podcasts } from './ducks/podcasts/reducer';
import { Podcast } from './ducks/podcast/reducer';
// import { teamReducer } from './ducks/who_we_are/reducer';

export default (history: History) =>
    combineReducers<State>({
        Speakers,
        Podcasts,
        Podcast,
        router: connectRouter(history),
    });
