import { count } from 'console';
import fetch from 'isomorphic-fetch';
import { apiUrl, token, locale } from '../../../../config';
import { PodcastsPayload } from '../types';
// import { getPodcasts } from '../actions'
export const getPodcastsRequest = (payload: PodcastsPayload) => {
    if (typeof payload !== 'undefined') {
        const start = payload.start;
        const url =
            apiUrl +
            '/api/podcast-plural?populate=Platform,File,Thumbnail&pagination[start]=' +
            start +
            '&pagination[limit]=3&sort[0]=Date%3Adesc';

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        };

        return fetch(url, {
            method: 'GET',
            headers,
        })
            .then(response => response.json())
            .then(data => data)
            .catch(error => {
                console.log('error', error);
            });
    }
};

export const getTopPodcastsRequest = () => {
    const url =
        apiUrl +
        '/api/podcast-plural?populate=Platform,File,Thumbnail&pagination[page]=1&pagination[pageSize]=1&sort[0]=Date%3Adesc ';
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
            console.log('error', error);
        });
};
