// import Podcasts from 'components/Podcasts/Podcasts';
import { fork, all } from 'redux-saga/effects';

import { routerSaga } from './ducks/router/saga';
import { SpeakersWatcher } from './ducks/speakers/saga';
import { PodcastsWatcher } from './ducks/podcasts/saga';
import { PodcastWatcher } from './ducks/podcast/saga';

export default function* rootSaga() {
    yield all([
        fork(routerSaga),
        fork(SpeakersWatcher),
        fork(PodcastsWatcher),
        fork(PodcastWatcher),
    ]);
}
