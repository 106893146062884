import { createActions } from 'redux-actions';
import { SpeakersResponse } from './types';

const { getSpeakersFailure, getSpeakersIdle, getSpeakers } = createActions(
    'GET_SPEAKERS_FAILURE',
    'GET_SPEAKERS_IDLE',
    'GET_SPEAKERS'
);

const { getSpeakersSuccess } = createActions<SpeakersResponse>(
    'GET_SPEAKERS_SUCCESS'
);

export { getSpeakersFailure, getSpeakersIdle, getSpeakers, getSpeakersSuccess };
