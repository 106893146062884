import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
    getSpeakers,
    getSpeakersSuccess,
    getSpeakersFailure,
    getSpeakersIdle,
} from './actions';
import { SpeakersState, SpeakersResponse } from './types';
import { Status } from 'types';

export const initialState: SpeakersState = {
    status: 'IDLE',
    data: [
        {
            id: 0,
            attributes: {
                Name: '',
                Description: '',
            },
        },
    ],
};
const status = handleActions<Status>(
    {
        [getSpeakersSuccess.toString()]: () => 'SUCCESS',
        [getSpeakers.toString()]: () => 'LOADING',
        [getSpeakersFailure.toString()]: () => 'FAILURE',
        [getSpeakersIdle.toString()]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getSpeakersSuccess.toString()]: (
            state,
            action: { payload: SpeakersResponse }
        ) => action.payload.data,
    },
    initialState.data
);

const Speakers = combineReducers({
    status,
    data,
});

export { Speakers };
