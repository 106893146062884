import loadable from '@loadable/component';
import { RouterFetchDataArgs } from 'types';

import { getSpeakers } from './store/ducks/speakers/actions';
import { getPodcasts } from './store/ducks/podcasts/actions';
import { getPodcast } from './store/ducks/podcast/actions';

const Home = loadable(() => import('./pages/Home'));
const Podcast = loadable(() => import('./pages/Podcast/Podcast'));
/**
 * Routes are moved to a separate file,
 * so that you can use the asyncFetchData method on the component on the server (by url path)
 * which load all the necessary data for rendering the page.
 */

export default [
    {
        path: '/',
        component: Home,
        exact: true,
        fetchData({ dispatch }: RouterFetchDataArgs) {
            dispatch(getSpeakers());
            dispatch(
                getPodcasts({
                    start: 1,
                })
            );
        },
    },
    {
        path: '/:id',
        component: Podcast,
        exact: true,
        fetchData({ dispatch, match }: RouterFetchDataArgs) {
            dispatch(
                getPodcast({
                    slug: match.params.id,
                })
            );
        },
    },
];
